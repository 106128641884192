<template>
  <div
    id="z-login-background"
    class="login-container"
    :style="`background-image: url('${backgroundImage}');`"
  >
    <div class="h-100">
      <router-view />
    </div>
    <notifications group="notifications" />
  </div>
</template>

<script>
export default {
  name: "InitLayout",

  data() {
    return {
      backgroundImage: require("@/assets/img/img-line.png")
    };
  }
};
</script>

<style lang="scss" scoped>
#z-login-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.login-container {
  @media only screen and (max-width: 767px) {
  }
}
</style>
